
import Vue from "vue";
import { atlasPlatformTableColumns } from "./AtlasPlatformTable";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import {
  atlasPlatformMapActions,
  atlasPlatformMapGetters
} from "@/store/modules/atlasPlatform";
import { cloneDeep } from "lodash";

interface IAlasPlatformData {
  tableActions: any[];
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  tableRowActions: any[];
  loadingText: string;
  platformId: string;
  selectedDocuments: string[];
  modalTitle: string;
  modalDescription: string;
  modalOkActionHandler: () => void;
  defaultQuery: {
    __limit: number;
    __page: number;
    __offset: number;
  };
}
interface IActions {
  text: string;
  key: string;
  subItems: { title: string; command: string }[];
}
export default Vue.extend({
  name: "AtlasPlatformListView",
  components: {
    NoResultsFound
  },
  data(): IAlasPlatformData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      loading: false,
      platformId: "",
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      loadingText: "",
      selectedDocuments: [],
      modalTitle: "",
      modalDescription: "",
      modalOkActionHandler: () => {},
      defaultQuery: {
        __limit: 50,
        __page: 1,
        __offset: 0
      }
    };
  },
  methods: {
    ...atlasPlatformMapActions([
      "getAtlasPlatforms",
      "deleteAtlasPlatform",
      "deleteAtlasPlatforms"
    ]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "createAtlasPlatform":
          this.$router.push(`/admin/atlasPlatform/create`);
          break;
        case "atlasPlatforms-activities":
          this.$router.push(`/admin/atlasPlatforms/activities`).catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading platforms. Please wait...";
        this.$emit("scrollTop");
        await this.getAtlasPlatforms({ ...queryObject });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    setModalData(modalTitle: string, description: string) {
      this.modalDescription = description;
      this.modalTitle = modalTitle;
    },
    resetData() {
      this.loadingText = "";
      this.isLoading = false;
      this.modalDescription = "";
      this.modalTitle = "";
      this.modalOkActionHandler = () => {};
      this.$modal.hide("atlasPlatformModal");
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/${_id}/atlasPlatform`).catch(() => {});
    },
    async createCallback(): Promise<any> {
      this.$router.push(`/admin/atlasPlatform/create`);
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          {
            this.setModalData(
              "Delete Atlas Platform",
              "This will delete the atlas platform. Continue?"
            );
            this.deleteCallback(data._id);
            this.modalOkActionHandler = this.deleteOneAtlasPlatform;
          }
          break;
        case "edit":
          this.$router.push(`/admin/${data._id}/atlasPlatform`);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneAtlasPlatform(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting Atlas Platform. Please wait...";
        await this.deleteAtlasPlatform(this.platformId);
        this.$appNotifySuccess("Platform Deleted");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(error.message);
      } finally {
        this.resetData();
      }
    },
    async deleteBulkAtlasPlatforms() {
      try {
        const _ids: string[] = this.selectedDocuments.map(
          (doc: any) => doc._id
        );
        await this.deleteAtlasPlatforms(_ids);
        await this.fetchPageData(this.defaultQuery);
        this.$appNotifySuccess("Platforms Deleted");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(error.message);
      } finally {
        this.resetData();
      }
    },
    deleteCallback(id: any): void {
      this.platformId = id;
      this.$modal.show("atlasPlatformModal");
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Delete Selected": {
          this.setModalData(
            "Delete Bulk Atlas Platforms",
            "This will delete the selected atlas platforms. Continue?"
          );
          this.$modal.show("atlasPlatformModal");
          this.modalOkActionHandler = this.deleteBulkAtlasPlatforms;
          break;
        }
        default:
          break;
      }
    }
  },
  computed: {
    ...atlasPlatformMapGetters([
      "atlasPlatforms",
      "getTotalRecords",
      "getAtlasPlatformsLinks"
    ]),
    tableColumns(): ITableWrapperColumns {
      return cloneDeep(atlasPlatformTableColumns);
    },
    bulkMenuActionsData(): { title: string; disabled: boolean }[] {
      return [
        {
          title: "Delete Selected",
          disabled: false
        }
      ];
    },
    topButtonSecondaryItems(): IActions {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const otherUsersOptions: IActions["subItems"] = [
        {
          title: "All Activities",
          command: "atlasPlatforms-activities"
        }
      ];

      return {
        ...primary,
        subItems: otherUsersOptions
      };
    }
  }
});
