import { formatDate } from "@/helpers/dateProcessor";

export const atlasPlatformTableColumns = {
  name: {
    formatDataKey: "Platform Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  createdOn: {
    formatDataKey: "Created At",
    formatDataValue: (arg: string) => formatDate(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 300
  }
};
